(function ($) {

  function new_map($el) {
    var $markers = $el.find('.marker').get().reverse();
    var args = {
      zoom: 19,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      navigationControl: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      disableDefaultUI: true,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    };

    var map = new google.maps.Map($el[0], args);
    map.markers = [];

    for(var i = 0; i < $markers.length; i++) {
      add_marker($markers[i], map);
    }

    center_map(map);

    return map;

  }

  function add_marker($marker, map) {

    $marker = $($marker);
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    var icon_size = 122;

    if (jQuery('html').hasClass('mobile')) {
      icon_size = 75;
    }

    var properSize = (jQuery($marker).data('icon').toLowerCase().indexOf("spiegelhofmap_icon-1.png") >= 0) ? new google.maps.Size(176, 176) : new google.maps.Size(80, 80);
    var properAnchor = (jQuery($marker).data('icon').toLowerCase().indexOf("spiegelhofmap_icon-1.png") >= 0) ? new google.maps.Point(88, 88) : new google.maps.Point(40, 40);

    var icon = {
      url: jQuery($marker).data('icon'),
      scaledSize: properSize ,
      anchor: properAnchor
    };

    var marker = new google.maps.Marker({
      position: latlng,
      icon: icon,
      map: map
    });

    map.markers.push(marker);

    if ($marker.html()) {

      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });
    }
  }

  function center_map(map) {
    var bounds = new google.maps.LatLngBounds();
    var latlng;

    $.each(map.markers, function (i, marker) {
      if(i == map.markers.length - 1) {
        latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(latlng);
      }

    });

    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(19);
      map.panBy(0, 0);
    } else {
      // fit to bounds
      map.setCenter(bounds.getCenter());
      map.panBy(0, 100);
      map.setZoom(15);
    }
  }

  var map = null;

  $(document).ready(function () {
    $('.acf-map').each(function () {
      // create map
      map = new_map($(this));
    });
  });

})(jQuery);
